<template>
	<div id="user-view" >
		<div class="text-center" v-if="loading">
			<v-progress-circular
			:size="50"
			color="primary"
			indeterminate
			></v-progress-circular>
		</div>
		<v-form
			v-else
			ref="formRef"
      		@submit.prevent="saveData"
		>

			<v-row class="ma-0 pa-0">
				<v-col cols="12" sm="12" md="12" class="align-self-end">
					<v-card	class="">
						<v-card-title>Hier können Sie mit den Angaben „Branche“ und „Produkt“ mit unterschiedlichen KI-Bild-Modellen passende Bilder erzeugen:</v-card-title>
						<v-card-text>
							<v-row>								
								<v-col cols="12" md="4">
									<v-autocomplete
										v-model="form.mainData.branche"
										:addressLoading="branchLoading"
										:items="branchItems"
										item-text="branche"
										item-value="branche"
										:search-input.sync="branchSearch"
										hide-details
										label="Branche eingeben"
										outlined
										dense
										:filter="fuzzyComparator"
										@blur="updateAddressModel"
										name="branche"
									>
									</v-autocomplete>
								</v-col>
								<v-col cols="12" md="4">
									<v-text-field
										v-model="form.mainData.produkt"
										label="Produkt (Keywords mit Komma getrennt)"
										type="text"
										outlined
										dense
										hide-details="auto"
										placeholder="Produkt (Keywords mit Komma getrennt)"
										name="produkt"
									>
									</v-text-field>
								</v-col>
								<v-col cols="12" md="4">
									<v-select
										v-model="form.mainData.model"
										label="KI Model"
										name="model"
										item-text='text'
										item-value='value'
										:items="[
											{value: 'midjourney', text: 'Midjourney'},
											{value: 'stabilityAi|sd3.5-large', text: 'stability.ai (sd3.5-large)'},											
											{value: 'stabilityAi|ultra', text: 'stability.ai (ultra)'},
											{value: 'stabilityAi|core', text: 'stability.ai (core)'},
											{value: 'runware|runware:101@1', text: 'Runware Flux.1 (dev)'},
											{value: 'runware|runware:100@1', text: 'Runware Flux.1 (schnell)'},
											{value: 'runware|civitai:139562@344487', text: 'Runware RealVisXL V4.0 (sdxl)'},
											{value: 'runware|civitai:139562@798204', text: 'Runware RealVisXL V5.0 (sdxllightning)'}
										]"
										outlined
										dense
										hide-details="auto"
									>
									</v-select>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>					
				</v-col>				
				<v-col cols="12" md="12">					
					<v-btn color="accent" type="submit">
						Bild generieren
					</v-btn>
				</v-col>
				<v-col cols="12" sm="12" md="12" class="align-self-end">
					<v-card	v-if="form.mainData.imageLoaded == true" class="imagePreview">
						<v-card-title>Generierte Bilder</v-card-title>
						<v-card-text>
							<v-row>								
								<v-col class="kiImage" v-if="form.mainData.imageSrc1" v-show="form.mainData.imageSrc1 != ''" cols="12" md="6">
									<img v-bind:src="form.mainData.imageSrc1" @click="downloadImage(form.mainData.imageSrc1,'image1',form.mainData.kennung1)" />
								</v-col>
								<v-col class="kiImage" v-if="form.mainData.imageSrc2" v-show="form.mainData.imageSrc2 != ''" cols="12" md="6">
									<img v-bind:src="form.mainData.imageSrc2" @click="downloadImage(form.mainData.imageSrc2,'image2',form.mainData.kennung2)" />
								</v-col>
								<v-col class="kiImage" v-if="form.mainData.imageSrc3" v-show="form.mainData.imageSrc3 != ''" cols="12" md="6">
									<img v-bind:src="form.mainData.imageSrc3" @click="downloadImage(form.mainData.imageSrc3,'image3',form.mainData.kennung3)" />
								</v-col>
								<v-col class="kiImage" v-if="form.mainData.imageSrc4" v-show="form.mainData.imageSrc4 != ''" cols="12" md="6">
									<img v-bind:src="form.mainData.imageSrc4" @click="downloadImage(form.mainData.imageSrc4,'image4',form.mainData.kennung4)" />
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import {onMounted, ref, getCurrentInstance, watch, nextTick} from '@vue/composition-api'
import router from '@/router'
import {
	mdiHomeOutline,
	mdiEmailOutline,
	mdiLockOutline,
	mdiEyeOffOutline,
	mdiEyeOutline
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";
import themeConfig from '@themeConfig'

export default {
	components: {

	},
	methods:{
		async downloadImage(imageSrc,name,kennung) {

			if (imageSrc.includes('midjourney')) {

				const upscaleIndex = name.replace('image','');
				const fd = new FormData();
				fd.append('aktion','upscaleMidjourneyImage');
				fd.append('kennung',kennung);
				fd.append('upscaleIndex',upscaleIndex);

				axios.post('/api/bildgenerierung/',fd)
				.then(async (response) => {

					const image = await fetch(response.data.image)
					const imageBlog = await image.blob()
					const imageURL = URL.createObjectURL(imageBlog)

					const link = document.createElement('a')
					link.href = imageURL
					link.download = name
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)

				}).catch(error => {
					console.log(error);
					vm.$toastr.e(error,error);
					loading.value = false;
				})


			} else {
				const image = await fetch(imageSrc)
				const imageBlog = await image.blob()
				const imageURL = URL.createObjectURL(imageBlog)

				const link = document.createElement('a')
				link.href = imageURL
				link.download = name
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}			
		}
	},
	setup(props) {

		const userTab = ref(null)
		const tabs = ref([
			{ icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
		])

		const vm = getCurrentInstance().proxy
		vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
		vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

		const formRef = ref(null)
		const form = ref({})

		const updateAddressModel = () => {
			// Update the underlying data model with the current value of the input field
			form.value.mainData.branche = form.value.mainData.branche;
		};

		form.value = {
			mainData : {
				idPtWerbemittel : router.currentRoute.params.idPtWerbemittel,
				titel : '',
				imageLoaded: false,
				imageSrc1: '',				
				imageSrc2: '',
				imageSrc3: '',
				imageSrc4: '',
				kennung1: '',
				kennung2: '',
				kennung3: '',
				kennung4: ''
			},
			timestamp: Date.now(),
			aktion: (router.currentRoute.params.idPtWerbemittel !== undefined ? 'editAdMaterialCheck' : 'createAdMaterialCheck'),
		};
		const loading = ref(true)

		var query = router.currentRoute.query;

		onMounted(()=>{

			loading.value = false;

		})

		const saveData = () => {
			// const isFormValid = formRef.value.validate()
			// if(!isFormValid) {
			// 	nextTick(() => {
			// 		const firstErrorField = document.querySelector('.error--text');
			// 		if (firstErrorField) {
			// 			firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
			// 		}
			// 	});
			// 	return
			// }
			// loading.value = true;

			const fd = new FormData();
			fd.append('aktion','generatePrompt');
			fd.append('branche',form.value.mainData.branche);
			fd.append('produkt',form.value.mainData.produkt);

			loading.value = true;
			form.value.mainData.imageSrc1 = '';
			form.value.mainData.kennung1 = '';
			form.value.mainData.imageSrc2 = '';
			form.value.mainData.kennung2 = '';
			form.value.mainData.imageSrc3 = '';
			form.value.mainData.kennung3 = '';
			form.value.mainData.imageSrc4 = '';
			form.value.mainData.kennung4 = '';

			axios
			.post('/api/bildgenerierung/',fd)
			.then(response => {

				if(response.data.prompt != ''){
					
					const fd = new FormData();
					fd.append('aktion','generateImage');
					fd.append('prompt',response.data.prompt);
					fd.append('model',form.value.mainData.model);

					axios.post('/api/bildgenerierung/',fd)
					.then(response => {

						response.data.forEach(function(imageObject) {

							if (imageObject.error == '') {
								form.value.mainData.imageSrc1 = imageObject.image;
								console.log(imageObject.image);
							}

						})

						if (response.data[0].error == '') {
							form.value.mainData.imageSrc1 = response.data[0].image;	
							if (response.data[0].kennung != undefined) {
								form.value.mainData.kennung1 = response.data[0].kennung;	
							}
						}
						if (response.data[1].error == '') {
							form.value.mainData.imageSrc2 = response.data[1].image;	
							if (response.data[1].kennung != undefined) {
								form.value.mainData.kennung2 = response.data[1].kennung;	
							}
						}
						if (response.data[2].error == '') {
							form.value.mainData.imageSrc3 = response.data[2].image;	
							if (response.data[2].kennung != undefined) {
								form.value.mainData.kennung3 = response.data[2].kennung;	
							}
						}
						if (response.data[3].error == '') {
							form.value.mainData.imageSrc4 = response.data[3].image;	
							if (response.data[3].kennung != undefined) {
								form.value.mainData.kennung4 = response.data[3].kennung;	
							}
						}
						
						form.value.mainData.imageLoaded = true;						
						loading.value = false;

					});

				} else {
					response.data.errors.forEach(item=>{
						let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
						vm.$toastr.e(item.errorText, item.errorTitle+suffix);
					})
					loading.value = false;
				}
				
			}).catch(error => {
				console.log(error);
				vm.$toastr.e(error,error);
				loading.value = false;
			})

		}

		const branchLoading = ref(false)
		const branchItems = ref([])
		const branchSearch = ref(null)
		const branchQuerySelections = query => {
			branchLoading.value = true

			let fd = new FormData();
			fd.append('aktion','fetchBranch');
			fd.append('mainData[branch]', query);

			branchItems.value = [];
			axios.post('/api/ajaxServer/',fd)
			.then(response => {
				console.log('loading')
					branchItems.value = response.data.hits;
					branchLoading.value = false
			})
		}
		const debouncedAddressQuerySelections = debounce(branchQuerySelections, 300, true);
		watch(branchSearch, query => {
			query && query !== form.value.mainData.branche && debouncedAddressQuerySelections(query)
		})
		function debounce(func, delay, trailing) {
			let timeoutId;
			return function () {
			const context = this;
			const args = arguments;
			clearTimeout(timeoutId);

			if (trailing) {
				timeoutId = setTimeout(() => {
				func.apply(context, args);
				}, delay);
			} else {
				func.apply(context, args);
			}
			};
		}		

		const fuzzyComparator = (a, searchQuery, c) => {
			return true;
		}

		return {
			branchLoading,
			branchItems,
			branchSearch,
			updateAddressModel,
			userTab,
			tabs,
			fuzzyComparator,
			form,
			formRef,
			saveData,
			loading,			
			icons: {
				mdiHomeOutline,
				mdiEmailOutline,
				mdiLockOutline,
				mdiEyeOffOutline,
				mdiEyeOutline,
			},
			validators: {
				required,
				emailValidator,
			},
		}
	},
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

// .imagePreview {
// 	display: none;
// }
.kiImage img {
	width: 100%;
	cursor: pointer;
}

.user-tabs{
	display: none;
}
#user-tabs-content{
	margin:0 !important;
}

#chart-sales-overview {
	position: relative;
	top:-30px;
	.apexcharts-canvas {
	.apexcharts-text {
		&.apexcharts-datalabel-value {
		font-weight: 600;
		}
		&.apexcharts-datalabel-label {
		font-size: 1rem;
		}
	}
	}
}

.sales-overview-stats-table {
	width: 100%;
	td {
	padding-bottom: 1rem;
	}

	.stats-dot {
	padding: 0.33rem;
	}

	// Set opacity of dots
	tr {
	&:nth-of-type(1) {
		td:nth-of-type(2) {
		.stats-dot {
			opacity: 0.7;
		}
		}
	}
	&:nth-of-type(2) {
		td:nth-of-type(1) {
		.stats-dot {
			opacity: 0.5;
		}
		}
		td:nth-of-type(2) {
		.stats-dot {
			opacity: 0.15;
		}
		}
	}
	}
}

.v-card__subtitle, .v-card__text #chart-sales-overview{
	font-size: unset;
}

.v-file-input {
	flex-direction: row-reverse;
}
.v-input--selection-controls {
	margin-top: 0px;
	padding-top: 0px;
}

.v-input__append-outer {
	white-space: nowrap;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
	position: absolute;
}
.gamification-tree {
	top: 10%;
	right: 0;
}
.gamification-john-pose-2 {
	bottom: 0;
	right: 15%;
}
.gamification-tree-4 {
	bottom: 0;
	right: 30%;
}

.refreshIcon {
	position: absolute !important;
	right: 30px;
}

@media (max-width: 600px) {
	.gamification-tree,
	.gamification-tree-4 {
	display: none;
	}
	.gamification-john-pose-2 {
	right: 5%;
	}
}

@media (max-width: 500px) {
	.gamification-john-pose-2 {
	max-width: 70px;
	}
}

@media (max-width: 400px) {
	.greeting-title {
	font-size: 1.2rem !important;
	}
}

// rtl
.v-application {
	&.v-application--is-rtl {
	.gamification-john-pose-2 {
		right: initial;
		left: 15%;
	}
	.gamification-tree {
		right: initial;
		left: 0;
	}
	.gamification-tree-4 {
		left: 30%;
		right: initial;
	}
	}
}
.v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar) {
	border-top-left-radius: unset;
	border-top-right-radius: unset;
}

.radio-image .v-input--selection-controls__input{
	display:none;
}
.radio-image .v-input--radio-group__input{
	justify-content: space-around;
}
</style>
