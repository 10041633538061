<template>
  <div id="user-view">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-form
      v-else
      ref="formRef"
      @submit.prevent="saveData"
    >
      <v-card

        class="mb-5"
        :title="form.aktion == 'editAdMaterialCheck' ? 'Werbemittel bearbeiten' : 'Werbemittel erstellen'"
      >
<!--        <v-card-title v-if="form.aktion == 'editAdMaterialCheck'">Werbemittel bearbeiten</v-card-title>
        <v-card-title v-else>Werbemittel hochladen</v-card-title>-->
        <v-card-title>Format wählen</v-card-title>

        <v-card-text class="mt-1">
          <v-radio-group
            v-model="form.mainData.format"
            row
            class="radio-image"
            :rules="[validators.required]"
            @change="handleFormatChange"
          >
            <v-radio v-for="item in formats" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
              <template v-slot:label>
                <div class="d-flex flex-column">
                  <img class="mb-5" width="300px" :src="item.image" :style="{ filter: form.mainData.format === item.value ? 'none' : 'grayscale(100%)' }" @click="form.mainData.format = item.value">

                  <template v-if="!item.disabled">
                    <a @click="openPdf(item.pdf.default || item.pdf)" >Detaillierte Informationen</a>
                  </template>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col cols="12" md="12" v-for="(selectedFileItem,selectedFileKey) in selectedFiles">
          <v-card class="mb-5 pa-0" v-if="selectedFileKey == 'dateiVorderseite' || (selectedFileKey == 'dateiRueckseite' && form.mainData.format != 'dina4mailing') ">
            <v-card-title>


              <span> {{selectedFileKey.replace('datei','').replace('ue','ü')}}</span>
              <span class="hint-text ml-3">({{selectedFormatsHintText}})</span>
            </v-card-title>

            <v-card-text>
              <v-file-input
                accept=".jpg,.jpeg,.png,.gif,.svg,.pdf"
                dense
                :placeholder="`Bilddatei oder PDF-Datei`"
                :label="`Bilddatei oder PDF-Datei`"
                :ref="selectedFileKey"
                @change="($event) => onFileSelected($event, selectedFileKey)"
                outlined
                :rules="form.aktion === 'createAdMaterialCheck' || (form.aktion === 'editAdMaterialCheck' && selectedFiles[selectedFileKey].changed) ? fileUploadRules(selectedFiles[selectedFileKey]) : []"
                @click:clear="deleteFile(selectedFileKey)"
                v-model="selectedFiles[selectedFileKey].file"
              />

              <div class="text-center" v-if="loadingFile[selectedFileKey]">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <template v-else>
                <template cols="12" md="2" v-if="selectedFiles[selectedFileKey].url != ''">
                  <template v-if="selectedFiles[selectedFileKey].file.type.startsWith('image/')">
                    <v-img
                      :src="selectedFiles[selectedFileKey].url"
                      width="100%"
                      @click="openImageDialog(selectedFileKey)"
                    ></v-img>
                  </template>
                  <template v-if="selectedFiles[selectedFileKey].file.type === 'application/pdf' && selectedFiles[selectedFileKey].pages.length > 0">
                    <v-select
                      v-model="selectedFiles[selectedFileKey].selectedPage"
                      hide-details="auto"
                      outlined
                      dense
                      :items="selectedFiles[selectedFileKey].pages"
                      item-text="value"
                      item-value="value"
                      label="Seite wählen"
                      :rules="[validators.required]"
                      @change="onFileSelected(selectedFiles[selectedFileKey].file, selectedFileKey, true)"
                    ></v-select>
                    <br>
                  </template>

                  <template v-if="selectedFiles[selectedFileKey].file.type === 'application/pdf' && selectedFiles[selectedFileKey].url != ''">

                    <template v-if="selectedFileKey == 'dateiRueckseite'">

                      <vue-pdf-with-draggable
                        :src="selectedFiles[selectedFileKey].url"
                        :srcLocale="selectedFiles[selectedFileKey].urlLocale"


                        :text="form.mainData.datenschutzTextEinstellungen.text"
                        :posX="form.mainData.datenschutzTextEinstellungen.posXInMM"
                        :posY="form.mainData.datenschutzTextEinstellungen.posYInMM"
                        :rotation="form.mainData.datenschutzTextEinstellungen.rotationInDeg"
                        :fontSize="form.mainData.datenschutzTextEinstellungen.fontSizeInMM"
                        :paddingLeft="form.mainData.datenschutzTextEinstellungen.paddingLeftInMM"
                        :paddingRight="form.mainData.datenschutzTextEinstellungen.paddingRightInMM"
                        :paddingTop="form.mainData.datenschutzTextEinstellungen.paddingTopInMM"
                        :paddingBottom="form.mainData.datenschutzTextEinstellungen.paddingBottomInMM"
                        :lineHeight="form.mainData.datenschutzTextEinstellungen.lineHeightInMM"
                        :width="form.mainData.datenschutzTextEinstellungen.widthInMM"
                        :useMovement="true"
                        :useFontHandle="true"
                        :useWidthHandle="true"
                        :useRotationHandle="true"
                        :useStampAndRecipientZone="true"
                        :useCodingZone="true"



                        :pageWidth="selectedFiles[selectedFileKey].dimension.width"
                        :pageHeight="selectedFiles[selectedFileKey].dimension.height"

                        @update:coordinates="handleCoordinatesUpdate"
                      />

                    </template>

                    <template v-else>
                      <vue-pdf-with-draggable
                        :src="selectedFiles[selectedFileKey].url"
                        :srcLocale="selectedFiles[selectedFileKey].urlLocale"
                        :pageWidth="selectedFiles[selectedFileKey].dimension.width"
                        :pageHeight="selectedFiles[selectedFileKey].dimension.height"
                        :text="form.mainData.angabeQrcodeEinfuegen ? 'QR-Code': ''"
                        :posX="form.mainData.qrcodeEinstellungen.posXInMM"
                        :posY="form.mainData.qrcodeEinstellungen.posYInMM"
                        :rotation="form.mainData.qrcodeEinstellungen.rotationInDeg"
                        :width="form.mainData.qrcodeEinstellungen.widthInMM"

                        :useMovement="true"
                        :useSquareContainer="true"
                        :useWidthAndHeightHandle="true"

                        @update:coordinates="handleQrCodeCoordinatesUpdate"
                      />


                      <v-checkbox
                        v-model="form.mainData.angabeQrcodeEinfuegen"
                        dense
                        label="QR-Code benutzen?"
                        hide-details
                      ></v-checkbox>
                    </template>


                  </template>
                </template>
              </template>
            </v-card-text>


          </v-card>
        </v-col>
      </v-row>

      <v-card class="mb-5">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            md="12"
            lg="12"
            class="pa-0"
          >
            <v-card-title>Werbemittel speichern</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="form.mainData.titel"
                label="Bitte Name für das Werbemittel vergeben"
                type="text"
                outlined
                dense
                hide-details="auto"
                :rules="[validators.required]"
              ></v-text-field>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mt-5">
        <v-col cols="12" md="12">
          <router-link :to="'/werbemittel'">
            <v-btn
              color="secondary"
              class="mx-2"
            >
              Zurück
            </v-btn>
          </router-link>
          <v-btn color="accent" type="submit">
            Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog
      v-model="imageDialog"
      :max-width="imageDialogFile.file.width+'px'"
    >
      <div class="d-flex justify-center align-center" style="height: 100%;">
        <img :src="imageDialogFile.url" width="auto" height="auto" class="centered-image" />
      </div>
    </v-dialog>
  </div>
</template>

<script>

import {onMounted, ref, getCurrentInstance,nextTick,computed } from '@vue/composition-api'
import isBlob from 'is-blob';
import router from '@/router'
import VuePdfWithDraggable from "@/views/VuePdfWithDraggable.vue";
import { debounce } from 'lodash';
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";
import themeConfig from '@themeConfig'
import useHelper from "@/views/useHelper";


export default {
  components: {
    VuePdfWithDraggable
  },
  setup(props) {

    const {
      getMatchedItem
    } = useHelper()

    const defaultFileItem = {
      'url':'',
      'urlurlLocale':'',
      'changed' : false,
      'fileKey' : '',
      'selectedPage' : 1,
      'pages' : [],
      'dimension' : {width:222, height:118,ratio:(222/118)},
      'file':{
        'type' : '',
        'changed' : false
      },
    };
    const selectedFiles = ref({
      "dateiVorderseite" : JSON.parse(JSON.stringify(defaultFileItem)),
      "dateiRueckseite" : JSON.parse(JSON.stringify(defaultFileItem)),
    })
    const onFileSelected = async (file, fileKey, forceParsing) => {

      loadingFile.value[fileKey] = true;

      try {

        if(file instanceof File && (file != selectedFiles.value[fileKey].lastFile || forceParsing)){

          if (file.type == 'application/pdf'){
            parsePdf(file,fileKey);
          }
          else{

            let selectedFormat = fetchFormatTupel(form.value.mainData.format);

            const fd = new FormData();
            fd.append('aktion',"saveImageAsPdf");
            fd.append('pdfWidth',selectedFormat.width);
            fd.append('pdfHeight',selectedFormat.height);

            if(isBlob(file)){
              fd.append('file', file, file.name);
            }

            axios
              .post('/api/werbemittel/',fd)
              .then(response => {

                if (response.data.valid) {

                  const { name, type, content } = response.data.file;
                  const byteCharacters = atob(content);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], { type });
                  const file = new File([blob], name, { type });

                  selectedFiles.value[fileKey].selectedPage = 1;
                  parsePdf(file,fileKey);
                }
                else{
                  response.data.errors.forEach(item=>{
                    let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                    vm.$toastr.e(item.errorText, item.errorTitle+suffix);
                  })
                }
              })
              .catch(error => {
                vm.$toastr.e(error,error);
              })
          }
        }
        else{
          loadingFile.value[fileKey] = false;
        }
      } catch (e) {
        loadingFile.value[fileKey] = false;
        vm.$toastr.e(e.message, e.message);
      }
    };

    const parsePdf = (file,fileKey) => {

      const fd = new FormData();
      fd.append('selectedPage',selectedFiles.value[fileKey].selectedPage);
      fd.append('aktion',"parsePdf");
      fd.append('file', file );

      axios
        .post('/api/werbemittel/',fd)
        .then(response => {

          if (response.data.valid) {

            const pages = ref([]);
            for(let i = 1; i <= response.data.pageCount; i++){
              pages.value.push({value:i});
            }

            let url = ref(response.data.url);
            selectedFiles.value[fileKey].url = url.value;
            selectedFiles.value[fileKey].urlLocale = response.data.urlLocale;
            selectedFiles.value[fileKey].fileKey = fileKey;
            selectedFiles.value[fileKey].changed = true;
            selectedFiles.value[fileKey].file = file;
            selectedFiles.value[fileKey].lastFile = file;
            selectedFiles.value[fileKey].lastPage = selectedFiles.value[fileKey].selectedPage;
            selectedFiles.value[fileKey].selectedPage = selectedFiles.value[fileKey].selectedPage;
            selectedFiles.value[fileKey].pageCount = response.data.pageCount;
            selectedFiles.value[fileKey].pages = pages;
            selectedFiles.value[fileKey].dimension = response.data.dimension;


            if(fileKey == "dateiVorderseite"){
              dateiVorderseite.value[0].validate()
            }
            if(fileKey == "dateiRueckseite"){
              console.log('dateiVorderseite',dateiVorderseite.value)
              dateiRueckseite.value[0].validate()
            }
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }

          loadingFile.value[fileKey] = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loadingFile.value[fileKey] = false;
        })
    }

    const deletedFiles = [];
    const deleteFile = (fileKey) => {
      selectedFiles.value[fileKey] = {...JSON.parse(JSON.stringify(defaultFileItem)), 'changed':true};
      deletedFiles.push(fileKey);
    }

    const formats = ref([
      {
        label: 'Maxipostkarte',
        value: 'maxipostkarte',
        image: require('@/assets/images/nitramit/2024-04-19-Maxipostkarte.png'),
        pdf: require('@/assets/pdfs/maxipostkarte.pdf'),
        width: 241,
        height: 131,
        disabled: false,
        hintText: "Endformat: 235 x 125 mm | Datenformat: 241 x 131 mm",
      },
      {
        label: 'DINA4-Mailing',
        value: 'dina4mailing',
        image: require('@/assets/images/nitramit/2025-01-16-DINA4-ein-seitig-blau.png'),
        pdf: require('@/assets/pdfs/dina4mailing.pdf'),
        width: 216,
        height: 303,
        disabled: false,
        hintText: "Endformat: 210 x 297mm | Datenformat: 216 x 303mm",
      },
      /*{
        label: 'Selfmailer',
        value: 'selfmailer',
        image: require('@/assets/images/nitramit/2024-04-19-Selfmailer.png'),
        pdf: require('@/assets/pdfs/selfmailer.pdf'),
        width: 0,
        height: 0,
        disabled: true,
      },*/
    ]);

    const handleFormatChange = () => {
      nextTick(() => {
        Object.entries(selectedFiles.value).forEach(([fileKey, value]) => {
          selectedFiles.value[fileKey].changed = true;
          selectedFiles.value[fileKey].file.changed = true;
        });
      })
    }

    const fetchFormatTupel = (value) => {
      let tupel = {
        label: "",
        value: "",
        width: 0,
        height: 0,
        hintText: 0,
      };
      formats.value.forEach(item => {
        if(item.value == value){
          tupel = item;
        }
      })
      return tupel;
    }

    const withinTolerance = (actual, expected, tolerance) => {
      return Math.abs(actual - expected) <= tolerance;
    };

    const fileUploadRulesBAK = [
      value => !!value || 'Bitte wählen Sie eine Datei aus',
      value => !value || value.size < 20000000 || 'Die Datei darf maximal nur 20 MB groß sein.',
    ];

    const fileUploadRules = (selectedFile) => [
      value => !!value || 'Bitte wählen Sie eine Datei aus',
      value => {

        if(selectedFile.file.type != "application/pdf") return true;

        const naturalWidth = selectedFile.dimension.width;
        const naturalHeight = selectedFile.dimension.height;
        const selectedFormat = fetchFormatTupel(form.value.mainData.format);

        const tolerance = 0.001;
        if (!withinTolerance(naturalWidth, selectedFormat.width, tolerance) || !withinTolerance(naturalHeight, selectedFormat.height, tolerance)) {
          return `
            Das PDF muss eine Größe von ${selectedFormat.width}mm x ${selectedFormat.height}mm haben.
            Sie haben statdessen eine Größe von ${Math.trunc(naturalWidth)}mm x ${Math.trunc(naturalHeight)}mm hochgeladen.
          `;
        }

        return true;
      },
      value => !value || value.size < 20000000 || 'Die Datei darf maximal nur 20 MB groß sein.',
    ];

    const imageDialog = ref(false);
    const imageDialogFile = ref(JSON.parse(JSON.stringify(defaultFileItem)));
    const openImageDialog = (fileKey) => {
      imageDialog.value = true;
      imageDialogFile.value = selectedFiles.value[fileKey];

    }
    const stringRules = (value) => !!value.trim() || 'Bitte ausfüllen';

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const formRef = ref(null)
    const dateiVorderseite = ref(null)
    const dateiRueckseite = ref(null)
    const form = ref({})

    form.value = {
      mainData : {
        idPtWerbemittel : router.currentRoute.params.idPtWerbemittel,
        titel : '',
        format : 'maxipostkarte',
        dateiVorderseite : '',
        dateiRueckseite : '',
        datenschutzTextEinstellungen : {
          text: "Der Datenherkunftstext wird erst bei der Erstellung der Kampagne eingefügt, da er von der gewählten Zielgruppe abhängt.",
          rotationInDeg: 0,
          fontSizeInMM: 2,
          posXInMM: 3,
          posYInMM: 3,
          paddingLeftInMM: 1,
          paddingRightInMM: 1,
          paddingTopInMM: 1,
          paddingBottomInMM: 1,
          lineHeightInMM: 2,
          widthInMM: 55
        },
        qrcodeEinstellungen : {
          text: "QR-Code",
          rotationInDeg: 0,
          posXInMM: 3,
          posYInMM: 3,
          widthInMM: 20
        },
        angabeQrcodeEinfuegen:false
      },
      aktion: (router.currentRoute.params.idPtWerbemittel !== undefined ? 'editAdMaterialCheck' : 'createAdMaterialCheck'),
    };
    const loading = ref(true)
    const loadingFile = ref({
      "dateiVorderseite" : false,
      "dateiRueckseite" : false,
    })

    const handleCoordinatesUpdate = (coordinates) => {
      form.value.mainData.datenschutzTextEinstellungen = coordinates;
    }

    const handleQrCodeCoordinatesUpdate = (coordinates) => {
      form.value.mainData.qrcodeEinstellungen = coordinates;
    }

    onMounted(()=>{

      if(form.value.aktion == 'editAdMaterialCheck'){

        loadingFile.value["dateiVorderseite"] = true;
        loadingFile.value["dateiRueckseite"] = true;

        axios.post('/api/werbemittel/', {
          aktion: 'editAdMaterial',
          mainData:{
            idPtWerbemittel : router.currentRoute.params.idPtWerbemittel,
          }
        })
          .then((response) => {
            if (response.data.valid === true) {

              form.value.mainData = response.data.adMaterial;
              console.log('response.data.adMaterial',response.data.adMaterial)

              if(response.data.adMaterial.dateiVorderseite){
                selectedFiles.value.dateiVorderseite = {
                  ...selectedFiles.value.dateiVorderseite,
                  ...form.value.mainData.dateiVorderseite
                };
              }

              if(response.data.adMaterial.dateiRueckseite){
                selectedFiles.value.dateiRueckseite = {
                  ...selectedFiles.value.dateiRueckseite,
                  ...form.value.mainData.dateiRueckseite
                };
              }

              selectedFiles.value = { ...selectedFiles.value };

              loadingFile.value["dateiVorderseite"] = false;
              loadingFile.value["dateiRueckseite"] = false;

            } else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })

              loadingFile.value["dateiVorderseite"] = false;
              loadingFile.value["dateiRueckseite"] = false;
            }
            loading.value = false;
          })
          .catch((error) => {
            console.log(error)
            loading.value = true;

            loadingFile.value["dateiVorderseite"] = false;
            loadingFile.value["dateiRueckseite"] = false;
          })
      }
      else{
        loading.value = false;
      }
    })

    const saveData = () => {
      const isFormValid = formRef.value.validate()
      if(!isFormValid)  {
        nextTick(() => {
          const firstErrorField = document.querySelector('.error--text');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return
      }
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion',form.value.aktion);
      fd.append('mainData[idPtWerbemittel]',form.value.mainData.idPtWerbemittel);
      fd.append('mainData[titel]',form.value.mainData.titel);
      fd.append('mainData[format]',form.value.mainData.format);
      fd.append('mainData[typ]','upload');
      fd.append('mainData[datenschutzTextEinstellungen]',JSON.stringify(form.value.mainData.datenschutzTextEinstellungen));
      fd.append('mainData[qrcodeEinstellungen]',JSON.stringify(form.value.mainData.qrcodeEinstellungen));
      fd.append('mainData[angabeQrcodeEinfuegen]',form.value.mainData.angabeQrcodeEinfuegen);

      deletedFiles.forEach((value,key) => {
        fd.append(`deletedFiles[${key}]`,value);
      })


      Object.entries(selectedFiles.value).forEach(([key, item]) => {
        if(item.file != undefined && isBlob(item.file)){
          fd.append(key, item.file, item.file.name);

          if(item.file.type === 'application/pdf'){
            fd.append(`${key}[selectedPage]`, item.selectedPage);
          }
        }
      });


      axios
        .post('/api/werbemittel/',fd)
        .then(response => {
          if(response.data.valid === true){
            router.push('/werbemittel',()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    const openPdf = (pdf) => {
      window.open(pdf, '_blank');
    }


    const selectedFormatsHintText = computed(() => {
      const selectedFormat = fetchFormatTupel(form.value.mainData.format);
      return selectedFormat.hintText;
    });

    return {
      form,
      formRef,
      saveData,
      loading,
      loadingFile,
      onFileSelected,
      stringRules,
      formats,
      fileUploadRules,
      selectedFiles,
      deleteFile,
      openImageDialog,
      imageDialog,
      imageDialogFile,
      handleFormatChange,
      parsePdf,
      handleCoordinatesUpdate,
      handleQrCodeCoordinatesUpdate,
      fetchFormatTupel,
      dateiVorderseite,
      dateiRueckseite,
      selectedFormatsHintText,
      File,
      openPdf,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.original-size-image {
  max-width: none; /* Allow the image to be larger than its original size */
  max-height: none;
}

.v-dialog  img{
  width: auto !important;
  height: auto !important;
}

.user-tabs{
  display: none;
}
#user-tabs-content{
  margin:0 !important;
}

#chart-sales-overview {
  position: relative;
  top:-30px;
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}

.v-card__subtitle, .v-card__text #chart-sales-overview{
  font-size: unset;
}

.v-file-input {
  flex-direction: row-reverse;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-input__append-outer {
  white-space: nowrap;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

.refreshIcon {
  position: absolute !important;
  right: 30px;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
.v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.radio-image .v-input--selection-controls__input{
  display:none;
}
.radio-image .v-input--radio-group__input{
  justify-content: space-around;
}
.hint-text {
  font-size: 0.7rem;
  color: gray;
}
</style>
