<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-with-images
          class="fill-height"
          :icon="ratingsOptions.icon"
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="totalAmountList"
          :stat-title="ratingsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        cols="12"
        sm="8"
      >
        <v-card class="fill-height">
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <div class="d-flex align-center pb-5">
              <v-text-field
                v-model="filter.searchString"
                dense
                outlined
                hide-details
                clearable
                placeholder="Werbemittel suchen"
                class="channel-list-search me-3"
              ></v-text-field>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card id="channel-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div class="d-flex align-center pb-5">
              <router-link :to="'/werbemittel/erstellen'" >
                <v-btn
                  color="accent"
                  class="me-3"
                >
                  <v-icon size="30" class="pr-2">{{icons.mdiPencilRuler }}</v-icon>
                  <span>Werbemittel erstellen</span>
                </v-btn>
              </router-link>
            </div>

            <div class="d-flex align-center pb-5">
              <router-link :to="'/werbemittel/hochladen'" >
                <v-btn
                  color="primary"
                  class="me-3"
                >
                  <v-icon size="30" class="pr-2">{{icons.mdiCloudUpload }}</v-icon>
                  <span>Eigenes Werbemittel hochladen</span>
                </v-btn>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="listAdMaterials"
          :options.sync="options"
          :server-items-length="totalAmountList"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :search="filter.searchString"
          class="text-no-wrap"
          no-data-text="Keine Einträge vorhanden"
          no-results-text="Ihre Suche ergab keinen Treffer"
          :footer-props="{
            itemsPerPageText: 'Anzahl pro Seite',
            itemsPerPageAllText: 'Alle',
            pageText: '{0}-{1} von {2}',
            itemsPerPageOptions: [5,10,25,50,-1]
          }"
        >
          <template
            v-slot:top
          >
            <v-dialog
              v-model="dialogDelete"
              width="500px"
            >
              <v-card>

                <v-toolbar flat>
                  <v-toolbar-title>Möchten Sie {{ deleteItem.titel }} wirklich löschen?</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeDelete">
                    <v-icon size="18" >
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="deleteAdMaterialConfirm"
                  >
                    Löschen
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeDelete"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template #[`item.idPtWerbemittel`]="{item}">
            #{{ item.idPtWerbemittel }}
          </template>
          <template #[`item.actions`]="{item}">
            <div>

              <v-tooltip
                bottom
                v-if="item.dateiVorderseite.file.size > 0 && item.dateiRueckseite.file.size > 0"
              >
                <template #activator="{ on, attrs }">
                  <a :href="`/api/werbemittel/?aktion=showAdMaterialPDF&mainData[idPtWerbemittel]=${item.idPtWerbemittel}`" target="_blank">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencilRuler }}
                      </v-icon>
                    </v-btn>
                  </a>
                </template>
                <span>Werbemittel Vorschau</span>
              </v-tooltip>

              <template class="" v-if="!item.entityLocked">

                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <router-link v-if="item.typ=='erstellmaske'" :to="'/werbemittel/editieren/'+item.idPtWerbemittel" >
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="18">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </v-btn>
                    </router-link>
                    <router-link v-else :to="'/werbemittel/hochladen/'+item.idPtWerbemittel" >
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="18">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </v-btn>
                    </router-link>
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>

                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteAdMaterial( item )"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Löschen</span>
                </v-tooltip>
              </template>
              <template v-else>
                <info-popup title="Hinweis: Werbemittel gesperrt">
                  <p>
                    Das von Ihnen ausgewählte Werbemittel ist derzeit gesperrt,
                    da es in einer aktiven Kampagne verwendet wird.
                  </p>
                  <p>
                    Änderungen an diesem Werbemittel sind nicht möglich,
                    solange die Kampagne nicht abgeschlossen, storniert
                    oder aufgrund eines Fehlers abgebrochen wurde.
                  </p>
                  <p>
                    Sobald die Kampagne ihren endgültigen Status erreicht hat,
                    wird das Werbemittel wieder zur Bearbeitung freigegeben.
                  </p>
                </info-popup>
              </template>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline, mdiFilePdf, mdiPencilRuler,mdiPlus,mdiClose,mdiCloudUpload,mdiInformationOutline
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import InfoPopup from "@/views/InfoPopup.vue";
import router from '@/router'
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";
import themeConfig from '@themeConfig'

export default {
  name: 'channel-list',
  components: {
    InlineDatePicker,
    InfoPopup,
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const ratingsOptions = {
      statTitle: 'Werbemittel',
      statistics: '13k',
      change: '+38%',
      chipText: 'Gesamt',
      chipColor: 'error',
      avatar: require('@/assets/images/nitramit/2024-02-26-icon-werbemittel-blau.png'),
      avatarWidth: '111',

    }

    return {
      vm,
      router,
      ratingsOptions
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiFilePdf,
        mdiPencilRuler,
        mdiCloudUpload,
        mdiPlus,
        mdiClose,
        mdiInformationOutline,
      },
      filter: { searchString: '', status: 'offene',datumVon:'',datumBis:''},
      loading: false,
      totalAmountList: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idPtWerbemittel'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idPtWerbemittel',
        },
        {text: 'Titel', value: 'titel'},
        {text: 'Typ', value: 'typDisplayName'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listAdMaterials: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idPtWerbemittel: 0,
        name: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idPtWerbemittel: 0,
        name: '',
      },
      defaultItem: {
        idPtWerbemittel: 0,
        name: '',
      },
      editValidationError : true,

    }
  },
  mounted() {
    this.fetchAdMaterials()
  },
  watch: {
    options: {
      handler() {
        this.fetchAdMaterials()
      },
    },
    filter: {
      handler() {
        this.fetchAdMaterials()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Werbemittel erstellen' : 'Werbemittel bearbeiten'
    },
  },
  methods: {
    fetchAdMaterials() {
      this.loading = true
      axios.post('api/werbemittel/', {
        aktion: 'showOverviewAdMaterials',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listAdMaterials = response.data.adMaterials || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteAdMaterial (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    deleteAdMaterialConfirm () {
      axios.post('api/werbemittel/', {
        aktion: 'deleteAdMaterial',
        mainData :{idPtWerbemittel: this.deleteItem.idPtWerbemittel},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchAdMaterials()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    editAdMaterial (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listAdMaterials.indexOf(item)
        this.editItem = Object.assign({}, item)
      }
    },
    titleRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Titel muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Titel kann maximal 45 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },

    editAdMaterialConfirm () {
      axios.post('api/werbemittel/', {
        aktion: this.editIndex === -1 ? 'createAdMaterialCheck' : 'editAdMaterialCheck',
        mainData: this.editItem,
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            this.fetchAdMaterials()
          }
          else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
}
</style>
