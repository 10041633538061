import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"hint":"d.m.Y format","dense":"","outlined":"","hide-details":"","clearable":"","persistent-hint":"","rules":_vm.rules,"error-messages":_vm.errorMessages,"readonly":""},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"primary","allowed-dates":_vm.allowedDates ? _vm.allowedDates : null,"firstDayOfWeek":"1","locale":"de-DE"},on:{"input":_vm.updateSelectedDate},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }