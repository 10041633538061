<template>
  <v-container>
    <v-tabs>
      <v-tab v-for="(articleLists, country) in pricelist" :key="country">
        {{ country.toUpperCase() }}
      </v-tab>
      <v-tab-item
        v-for="(articleLists, country) in pricelist"
        :key="country"
        class="pa-4"
        eager="true"
      >
        <v-tabs>
          <v-tab v-for="(articleList, adMaterialFormat) in articleLists" :key="adMaterialFormat">
            {{ adMaterialFormat }}
          </v-tab>
          <v-tab-item
            v-for="(articleList, adMaterialFormat) in articleLists"
            :key="adMaterialFormat"
            class="pa-4"
            eager="true"
          >

            <v-tabs>
              <v-tab
                v-for="(articleSettings, articleType) in articleList"
                :key="articleType"
              >
                {{ articleType }}
              </v-tab>
              <v-tab-item
                v-for="(articleSettings, articleType) in articleList"
                :key="articleType"
                class="pa-4"
                eager="true"
              >
                <v-simple-table v-if="['porto', 'druck'].includes(articleType)">
                  <thead>
                  <tr>

                    <th>Preis</th>
                    <th>Beschreibung</th>

                    <th>Min</th>
                    <th>Max</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="item in articleSettings"
                    :key="item.internalName"
                  >

                    <td class="price">{{ item.price }}</td>
                    <td>{{ item.title }}</td>

                    <td>{{ item.min || '-' }}</td>
                    <td>{{ item.max || '-' }}</td>
                  </tr>
                  </tbody>
                </v-simple-table>

                <v-simple-table v-else>
                  <thead>
                  <tr>
                    <th>Preis</th>
                    <th>Beschreibung</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="item in articleSettings"
                    :key="item.internalName"
                  >
                    <td class="price">{{ item.price }}</td>
                    <td >{{ item.title }}</td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import useHelper from "@/views/useHelper";
import { ref, onMounted } from "@vue/composition-api";
import axios from "axios";
import InfoPopup from "@/views/InfoPopup";

const { convertToGermanFormat } = useHelper();

export default {
  name: "GeneralPriceList",
  components: { InfoPopup },
  setup() {
    const pricelist = ref({});

    onMounted(() => {
      axios
        .get("/api/ajaxServer/?aktion=fetchGeneralPricelist")
        .then((response) => {
          pricelist.value = response.data.pricelist;
          console.log(pricelist.value);
        });
    });

    return {
      pricelist,
      convertToGermanFormat,
    };
  },
};
</script>

<style scoped>

.price{
  width:150px;
}

</style>
